import { SHARED_MEETING_LIST_FETCH_LIMIT } from "../utils/Const";

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL; // Set your base URL here
export const SOCKET_BASE_URL = process.env.REACT_APP_SOCKET_BASE_URL;
export const REACT_APP_AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;
export const REACT_APP_AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
export const REACT_APP_AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE;
export const REACT_APP_PUBLIC_POSTHOG_KEY =
  process.env.REACT_APP_PUBLIC_POSTHOG_KEY;
export const REACT_APP_PUBLIC_POSTHOG_HOST =
  process.env.REACT_APP_PUBLIC_POSTHOG_HOST;

const API_VERSION = "/api/1.0";

export const ROUTES = {
  GET_SCRATCHPAD_NOTES: (meetingId) => `${API_VERSION}/advisor/meeting/${meetingId}/get-scratchpad-notes`,
  UPDATE_SCRATCHPAD_NOTES: (meetingId) => `${API_VERSION}/advisor/meeting/${meetingId}/update-scratchpad-notes`,
  USER_PROFILE: `${API_VERSION}/advisor/user-profile`,
  USER_PROFILE_UPDATE: `${API_VERSION}/advisor/user-profile/update`,
  CLIENT_LIST: `${API_VERSION}/advisor/client/list`,
  CLIENT_DETAILS: (clientId) => `${API_VERSION}/advisor/client/${clientId}`,
  TEAM_MEMBER_LIST:  `${API_VERSION}/advisor/org-advisors/list`,
  MEETING_LIST: (offset) => `${API_VERSION}/advisor/meeting/list?offset=${offset}&limit=10`,
  SHARED_MEETING_LIST: (user_id,offset,client_ids=[],filter_month='',filter_year='',team_member_ids=[]) => `${API_VERSION}/advisor/meeting/shared/list-v2?user_id=${user_id}&offset=${offset}&client_ids=${client_ids}&filter_month=${filter_month}&filter_year=${filter_year}&team_member_ids=${team_member_ids}&limit=${SHARED_MEETING_LIST_FETCH_LIMIT}`,
  MEETING_LIST_V2: (user_id,cursor_time='',client_ids=[],filter_month='',filter_year='') => `${API_VERSION}/advisor/meeting/list-v2?user_id=${user_id}&cursor=${cursor_time}&client_ids=${client_ids}&filter_month=${filter_month}&filter_year=${filter_year}`,
  CLIENT_MEETING_LIST: (clientId) => `${API_VERSION}/advisor/client/${clientId}/meeting/list`,
  CREATE_CLIENT: `${API_VERSION}/advisor/client/create`,
  MEETING_DETAILS: (meetingId) => `${API_VERSION}/advisor/meeting/${meetingId}`,
  WS_SOCKET: (meetingId, token) =>
    `${SOCKET_BASE_URL}/ws/1.0/advisor/meeting/${meetingId}/ws/connect?code=${token}`,
  MEETING_UPDATE: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/update`,
  MEETING_CREATE: `${API_VERSION}/advisor/meeting/new-api/create`,
  CONTACT_LIST: `${API_VERSION}/advisor/client/list`,
  ADVISORS_LIST: `${API_VERSION}/advisor/org-advisors/list`,
  MEETING_TYPE_LIST: `${API_VERSION}/advisor/meeting-type/list`,
  USER_INTERACTION: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/user-interaction/update`,
  REGISTER_CALL_AI_ADVISOR: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/register-call-for-ai-advisor`,
  REGISTER_CALL_AI_CLIENT: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/register-call-for-ai-client`,
  DOWNLOAD_NOTES: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/download-notes`,
  APP_VERSION: `${API_VERSION}/app-version`,
  DELETE_MEETING: (meetingId) => `${API_VERSION}/advisor/meeting/${meetingId}/delete`,
  GET_EMONEY_DETAILS: (meetingId) => `${API_VERSION}/advisor/meeting/${meetingId}/integration/get-emoney-data`,
  CALENDAR_EXISTS: `${API_VERSION}/advisor/calendar/exists`,
  SYNC_CALENDER: `${API_VERSION}/advisor/calendar/sync`,
  MEETING_TYPES_LIST: `${API_VERSION}/advisor/meeting-type/list`,
  UPDATE_MEETING_TYPE: (meeting_id)=>`${API_VERSION}/advisor/meeting/${meeting_id}/update-meeting-type`,
  CONNECT_CALENDAR:(token, redirect_uri) => `${API_BASE_URL}${API_VERSION}/integration/microsoft?token=${token}&mili_redirect_url=${redirect_uri}`,
  //share
  GET_SHARED_LIST: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/shared-users/list`,
  GET_SHAREABLE_LIST: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/shareable-users/list`,
  UPDATE_MEETING_SHARE_LIST: (meetingId) =>
    `${API_VERSION}/advisor/meeting/${meetingId}/update/share`,

  ADD_NOTE_TO_SALESFORCE: (meetingId) => `${API_VERSION}/advisor/meeting/${meetingId}/integration/salesforce/add-notes`,
  ADD_ACTION_ITEMS_TO_SALESFORCE: (meetingId) => `${API_VERSION}/advisor/meeting/${meetingId}/integration/salesforce/add-action-items`,
  FINALIZE_MEETING_NOTES: (meetingId) => `${API_VERSION}/advisor/meeting/${meetingId}/finalize-notes`,
  ORGANIZATION_DETAILS: `${API_VERSION}/advisor/organization-details`,
  //auth
  SEND_OTP: `${API_VERSION}/auth/send-otp`,
  VERIFY_OTP: `${API_VERSION}/auth/verify-otp`,
  REFRESH_TOKEN: `${API_VERSION}/auth/refresh-token`,
  LOGOUT: `https://${REACT_APP_AUTH0_DOMAIN}/v2/logout?client_id=${REACT_APP_AUTH0_CLIENT_ID}`,
  LIVEBLOCKS_AUTH: (meetingId: string) => 
    `${API_VERSION}/advisor/meeting/${meetingId}/liveblocks-auth`,

};
