import { useEffect } from "react";
import { Provider } from "react-redux";
import store from "./redux/store";
import Router from "./router";
import { setToken } from "./redux/reducers/appSlice";
import { fetchUserProfile, fetchOrganizationDetails } from "./redux/actions/appActions";
import { SocketProvider } from "./context/SocketContext";
import { useToast } from "./components/toast/useToast";
import { toastEmitter } from "./components/toast/toastManager";
import { injectMicrophonePermissionIframe, isExtension } from "./utils/Util";

if (isExtension()) {
  injectMicrophonePermissionIframe();
}

// Check if token exists in localStorage and dispatch setToken action if it does
const token = localStorage.getItem("token");
if (token) {
  store.dispatch(setToken(token));
}

const App = () => {
  const ToastComponent = useToast();

  useEffect(() => {
    if (token) {
      store.dispatch(fetchUserProfile());
      store.dispatch(fetchOrganizationDetails());
    }
  }, []);

  useEffect(() => {
    const messageListener = (message, sender, sendResponse) => {
      if (message.action === "processCompleted") {
        showSuccess(message.taskName + "  has been completed.");
      }
    };

    try {
      chrome?.runtime?.onMessage.addListener(messageListener);
    } catch (err) {
      console.log("chrome err:", err);
    }

    // Cleanup the listener when the component unmounts
    return () => {
      try {
        chrome?.runtime?.onMessage.removeListener(messageListener);
      } catch (err) {
        console.log("chrome err:", err);
      }
    };
  }, []);

  const showSuccess = (message) => {
    toastEmitter.show({ message: message, severity: "success" });
  };

  return (
    <Provider store={store}>
      <SocketProvider>
        <Router />
      </SocketProvider>
      {ToastComponent}
    </Provider>
  );
};

export default App;
